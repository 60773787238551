import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Callout from '../components/callout'

const TemplateService = ({ data }) => {
  const {
    serviceJson: { name, description, images, seo },
  } = data
  return (
    <Layout>
      <SEO
        description={seo?.description ?? description}
        title={seo?.title ?? name}
      />
      <main className="max-w-4xl mx-auto px-4 pt-3 lg:pt-12 mb-12 w-full">
        <h1 className="text-lg lg:text-2xl text-center mb-6">{name}</h1>
        <div
          className="text-center lg:text-left text-sm lg:text-base mb-6 lg:mb-12"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <ul>
          {images?.map((image, index) => (
            <li className="mb-3 lg:mb-12" key={index}>
              <Img className="shadow" fluid={image.childImageSharp.fluid} />
            </li>
          ))}
        </ul>
      </main>
      <aside>
        <Callout />
      </aside>
    </Layout>
  )
}

export const query = graphql`
  query GET_SERVICE($slug: String!) {
    serviceJson(slug: { eq: $slug }) {
      name
      description
      seo {
        title
        description
      }
      images {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default TemplateService
